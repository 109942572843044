@import '../../../assets/styles/colors.scss';

.button {
  outline: none;
  border: none;
  background-color: $Orange;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px;
  height: 44px;
  border-radius: 20px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}