@import '../../../assets/styles/colors.scss';

.input {
  &_wrapper {
    width: 100%;
  }
  &_field {
    padding: 8px;
    background-color: transparent;
    border-top: none;
    height: 33px;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid $DarkGrey;
    outline: none;
    width: 100%;
    color: white;
    font-size: 20px;
    line-height: 25px;
    &::placeholder {
      color: $DarkGrey;
      font-size: 20px;
      line-height: 25px;
    }
  }
  &_error {
    color: red;
    background-color: $White;
    padding: 0 8px;
  }
}