@import './assets/styles/colors.scss';

.App {
  top: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.ant-col.ant-form-item-label {
  padding: 0 !important;
}

.main_wrapper {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  background-image: url('./assets/img/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 82px;
  justify-content: center;
  align-items: flex-start;
}

.standard-modal {
  .ant-modal-title {
    color: $Blue;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 25px;
    @media all and (max-width: 767px) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .ant-form-item-label label {
    color: $Blue !important;
    padding: 0 !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 25px !important;
  }

}

.tox-notifications-container {
  display: none;
}

.page-title {
  font-size: 30px;
  font-weight: 600;
  color: $Blue;
  margin-bottom: 20px;

  @media all and (max-width: 767px) {
    font-size: 16px;
  }
}

.page-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.page-hint {
  font-size: 30px;
  font-weight: 400;
  color: $Blue;
  display: flex;
  height: 80%;
  justify-content: center;
  align-items: center;
}

.no-items-text {
  color: $Blue;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
}

.non-input {
  outline: none;
  border: none;
  background: transparent;
}