@import '../../../assets/styles/colors.scss';

.auth {
  &_modal {
    opacity: 0.95;
  }
  &_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 250px;
    width: 359px;
  }
  &_title {
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
}