@import '../../../assets/styles/colors.scss';

.login {
  &_subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    max-width: 297px;
    text-align: center;
  }
  &_form {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    gap: 26px;
  }
  &_links_container {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  &_button {
    width: 281px;
  }
  &_link {
    color: $Orange;
    margin-top: 40px;
    text-decoration: none;
  }
}