@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans ExtraBold'), local('NunitoSans-ExtraBold'),
        url('NunitoSans-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
        url('NunitoSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans ExtraLight Italic'), local('NunitoSans-ExtraLightItalic'),
        url('NunitoSans-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Light'), local('NunitoSans-Light'),
        url('NunitoSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans ExtraBold Italic'), local('NunitoSans-ExtraBoldItalic'),
        url('NunitoSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Black Italic'), local('NunitoSans-BlackItalic'),
        url('NunitoSans-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
        url('NunitoSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Italic'), local('NunitoSans-Italic'),
        url('NunitoSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans ExtraLight'), local('NunitoSans-ExtraLight'),
        url('NunitoSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Light Italic'), local('NunitoSans-LightItalic'),
        url('NunitoSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Bold Italic'), local('NunitoSans-BoldItalic'),
        url('NunitoSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans Black'), local('NunitoSans-Black'),
        url('NunitoSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
        url('NunitoSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito Sans';
    src: local('Nunito Sans SemiBold Italic'), local('NunitoSans-SemiBoldItalic'),
        url('NunitoSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: block;
}

