@import '../../../assets/styles/colors.scss';

.signin {
  &_subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    max-width: 297px;
    text-align: center;
  }
  &_form {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    gap: 26px;
  }
  &_button {
    width: 281px;
  }
  &_link {
    &_conf {
      color: $Orange;
      margin-top: 40px;
    }
    &_login {
      margin-top: 40px;
      color: $Light;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.confirm {
  &_text {
    margin: 0 auto 18px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  &_link {
    color: $Orange;
    text-decoration: none;
  }
}

.email-error {
  &-text {
    text-align: center;
    margin: 0 auto;
  }
  &-text:not(:first-child) {
    margin-top: 24px;
  }
}