@import '../../../assets/styles/colors.scss';

.password-recovery {
  &-text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #F5F4F2;
  }
  &-input {
    margin:40px 0;
  }
  &_links_container {
    display: flex;
    flex-direction: row;
    gap: 60px;
  }
  &_link {
    color: $Orange;
    margin-top: 40px;
    text-decoration: none;
  }
}
.confirm {
  &_text {
    margin: 0 auto 18px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  &_link {
    color: $Orange;
    text-decoration: none;
  }
}