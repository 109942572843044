@import '../../../assets/styles/colors.scss';

.modal {
  &_wrapper {
    display: flex;
    margin: 0 auto;
    min-width: 300px;
    border-radius: 20px;
    background-color: $Blue;
    padding: 30px 53px 38px;
    color: $White;
    min-height: fit-content;
  }
}