@import '../../../assets/styles/colors.scss';

.password {
  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }
  &-button {
    width: 281px;
  }
  &-confirm-input {
    margin-top: 38px;
    margin-bottom: 40px;
  }
}
.login_link {
  color: $Orange;
  text-decoration: none;
  margin-top: 32px;
}